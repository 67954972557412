<template lang="pug">
	.d-flex.flex-column.flex-md-row.overflow-visible.basic-box-shadow.border-rounded.foreground-color.resources-card(v-if="card")
		.d-flex.flex-column.flex-md-row.align-items-center.w-100
			.d-flex.align-items-center.pr-md-3.resources-card__item
				.d-flex.flex-column.w-100.mb-3.mb-md-0
					.d-flex.align-items-center
						.d-flex.align-items-center
							.d-flex.justify-content-center.align-items-center.resources-card__avatars(:class="{'brand-gradient': !card.image}")
								img(v-if="card.image" :src="card.image.contentUrl")
								span(v-else) {{ `${card.title}`.slice(0,1) }}
							.d-flex.flex-column.ml-3
								span.b3.mb-1.mr-1.mr-md-0.overflow-wrap {{ card.title }}
								span.b3.resources-card__resource {{ card.isGroup() ? $t('resources.group') : $t('resources.channel') }}
						svg.d-block.ml-auto.resources-card__dots(v-if="isMobile" @click="onOpenPopover" :id="card.title" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none")
							g(fill="#52A7F9" clip-path="url(#a)")
								path(d="M1.5 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM9 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM16.5 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z")
									defs
										clipPath(id="a")
											path(fill="#fff" d="M0 0h18v18H0z")
						b-popover(v-if="isMobile" ref="popoverMenuDots" :target="card.title" :custom-class="'popover-resources'")
							i.my-1.fontello-btn.fontello-btn-red.fontello-icon-trash( @click="openModalRemove(card)")
			.d-flex.flex-md-wrap.flex-xl-nowrap.align-items-center.px-md-3.mb-2.mb-md-0.resources-card__item
				span.alerts.px-3.justify-content-between.resources-card__item-alert(:class="isBotConnected ? 'alert-success' : 'alert-error'")
					span {{ isBotConnected ? $t('resources.botConnected') : $t('resources.botNotConnected') }}
					span.cursor-pointer.resources-card__tooltip(
						v-if="!isBotConnected"
						:id="!isMobile ? `botTooltip-${card.id}` : ''"
						@click="isMobile ? $bvModal.show(`botTooltip-${card.id}`) : ''"
					) ?
					.resources-card__tooltip(v-if="isBotConnected")
						svg( width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg")
							path(d="M2.44531 7.5072L5.19356 10.5303L12.5222 2.46875" stroke="#59CB94" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round")
				//- span.alerts.ml-1.ml-md-0.ml-xl-1.mt-md-2.mt-xl-0.resources-card__item-alert(v-if="isBotConnected" @click="getInviteLink") Get Invite Link
				.alerts.ml-1.ml-md-0.ml-xl-1.mt-md-2.mt-xl-0.resources-card__item-alert(v-if="!isBotConnected" @click='checkAccess')
					svg(width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg")
						path(d="M18.0885 18.6283C16.8014 19.8107 15.1979 20.5927 13.4738 20.8789C11.7497 21.1651 9.9795 20.9431 8.37944 20.24C6.77938 19.5369 5.41865 18.3832 4.46337 16.9196C3.50809 15.4561 2.99961 13.7461 3 11.9983H4.5C4.49989 13.509 4.95597 14.9845 5.80851 16.2316C6.66106 17.4786 7.87032 18.4392 9.27796 18.9875C10.6856 19.5358 12.226 19.6462 13.6975 19.3043C15.1689 18.9624 16.5028 18.1841 17.5245 17.0713H15.345C15.1461 17.0713 14.9553 16.9923 14.8147 16.8517C14.674 16.711 14.595 16.5203 14.595 16.3213C14.595 16.1224 14.674 15.9317 14.8147 15.791C14.9553 15.6504 15.1461 15.5713 15.345 15.5713H18.8385C19.0374 15.5713 19.2282 15.6504 19.3688 15.791C19.5095 15.9317 19.5885 16.1224 19.5885 16.3213V19.8118C19.5885 20.0108 19.5095 20.2015 19.3688 20.3422C19.2282 20.4828 19.0374 20.5618 18.8385 20.5618C18.6396 20.5618 18.4488 20.4828 18.3082 20.3422C18.1675 20.2015 18.0885 20.0108 18.0885 19.8118V18.6268V18.6283ZM6.4755 6.92685H8.655C8.85391 6.92685 9.04468 7.00587 9.18533 7.14652C9.32598 7.28717 9.405 7.47793 9.405 7.67685C9.405 7.87576 9.32598 8.06652 9.18533 8.20718C9.04468 8.34783 8.85391 8.42685 8.655 8.42685H5.16C4.96109 8.42685 4.77032 8.34783 4.62967 8.20718C4.48902 8.06652 4.41 7.87576 4.41 7.67685V4.18335C4.41 3.98443 4.48902 3.79367 4.62967 3.65302C4.77032 3.51236 4.96109 3.43335 5.16 3.43335C5.35891 3.43335 5.54968 3.51236 5.69033 3.65302C5.83098 3.79367 5.91 3.98443 5.91 4.18335V5.36835C7.19693 4.18455 8.80084 3.40128 10.5257 3.11426C12.2506 2.82724 14.0217 3.04891 15.6227 3.75216C17.2236 4.45542 18.585 5.6098 19.5405 7.07424C20.496 8.53869 21.0042 10.2498 21.003 11.9983H19.503C19.5031 10.4875 19.047 9.01183 18.1942 7.76463C17.3415 6.51743 16.132 5.55685 14.7241 5.0087C13.3162 4.46055 11.7755 4.3504 10.304 4.69266C8.83239 5.03493 7.4985 5.81365 6.477 6.92685H6.4755Z" fill="#52A7F9")
			.d-flex.align-items-center.px-md-3.resources-card__item.resources-card__item--dropdown-plans
				dropdown-plans(v-if="plans.length > 0" :discount="card.discount" :list="plans" :id="card.id" :resources="true")
				span.d-flex.justify-content-center.align-items-center.w-100.resources-card__no-plans(v-if="plans.length == 0") {{ $t('resources.noPlans') }}
		.d-flex.align-items-center.pl-md-2(v-if="!isMobile")
			i.ml-1.fontello-btn.fontello-btn-red.fontello-icon-trash(@click='openModalRemove(card)')

		modal-remove-promotion(:id="`resources-${card.id}`" :title="$t('resources.delete.title')" :text="name" :btnText="textRemovedBtn" :btnClass='classRemoveBtn' :item='removeItem' :showBtnClose='removeItemShowBtnClose')

		modal-tooltip(
			v-if="!isBotConnected"
			:id="`botTooltip-${card.id}`"
			:href="'https://docs.paywall.pw/rus/monetizaciya-kontenta-i-soobshestv/resursy/kak-proverit-prava-bota'"
			:textLink="`🤖&nbsp;${$t('tutorials.addTitle')}`"
			:title="$t('resources.botNotConnected')"
			:text="$t('resources.botTooltip')"
			:textMobile="$t('resources.botTooltip')"
		)

</template>

<script>
import DropdownPlans from '@/components/Dropdown/DropdownPlans';
import RemovePromotion from '@/components/Modals/RemovePromotion';
import PwBtnLink from '@/components/Buttons/PwBtnLink';
import ModalTooltip from '@/components/Modals/ModalTooltip';
import IconClose from '@/components/Common/IconClose';
import { mapActions, mapGetters } from 'vuex';
import { DEVICE } from '@/utils/device';
import { STORAGE_LAST_OPENED_ITEM } from '@/api/storage';

export default {
    name: 'ResourcesCard',
    components: {
        DropdownPlans,
        'modal-remove-promotion': RemovePromotion,
        PwBtnLink,
        IconClose,
        ModalTooltip,
    },
    props: {
        card: {
            default: () => {},
            type: Object,
        },
    },
    data: () => ({
        overlay: false,
        removeItem: null,
        removeItemShowBtnClose: true,
        name: '',
    }),
    created() {
        this.canBeDeleted({ id: this.project.id }).then(v => {
            this.project.setCanBeDeleted(v);
        });
        window.addEventListener('click', e => {
            if (this.overlay === true) {
                const target = e.target;

                if (!target.closest('.b-popover') && !target.closest('.resources-card__dots')) {
                    this.onClosePopover();
                }
            }
        });
    },
    computed: {
        ...mapGetters({
            project: 'project/opened',
        }),
        plans() {
            if (!this.project) return [];

            return _.filter(
                this.project.plans,
                p => _.some(p.channels, c => c.id == this.card.id) || _.some(p.groups, c => c.id == this.card.id)
            );
        },
        isBotConnected() {
            return this.card && !(this.card.hasBotIssue() || this.card.isBotRemoved());
        },
        lastResourceForPlans() {
            return _.filter(this.project.plans, p => {
                if (p.channels.length == 1 && p.channels[0].id == this.card.id && p.groups.length == 0) {
                    return p;
                }

                if (p.groups.length == 1 && p.groups[0].id == this.card.id && p.channels.length == 0) {
                    return p;
                }
            });
        },
        subscriptionResources() {
            let resources = [];

            this.project.plans.map(p => {
                if (p.canBeDeleted == false) {
                    if (p.channels) resources = [...resources, ...p.channels];

                    if (p.groups) resources = [...resources, ...p.groups];
                }
            });

            return resources;
        },
        classRemoveBtn() {
            return this.removeItemShowBtnClose ? 'btn-peril' : 'btn-primary';
        },
        textRemovedBtn() {
            return this.removeItemShowBtnClose ? this.$t('resources.delete.btnText') : this.$t('social.understand');
        },
    },
    methods: {
        ...mapActions({
            canBeDeleted: 'project/canBeDeleted',
            inviteLink: 'resources/inviteLink',
            getChannel: 'resources/channel_check',
            getGroup: 'resources/group_check',
            getProject: 'project/id',
        }),
        onOpenPopover() {
            this.$refs.popoverMenuDots.$emit('open');
            document.body.classList.add('overlay');
            this.overlay = true;
        },
        onClosePopover(id) {
            if (this.$refs.popoverMenuDots) this.$refs.popoverMenuDots.$emit('close');

            document.body.classList.remove('overlay');
            this.overlay = false;
            this.$root.$emit('bv::show::modal', id);
        },
        async copy(text) {
            setTimeout(() => {
                if (DEVICE.isSafari()) {
                    DEVICE.copy(text);
                    return this.$notify('success', `😊 &nbsp;&nbsp;&nbsp;${this.$t('resources.copy')}`);
                }

                this.$copyText(text)
                    .then(e => this.$notify('success', `😊 &nbsp;&nbsp;&nbsp;${this.$t('resources.copy')}`))
                    .catch(e => this.$notify('error', `😢 &nbsp;&nbsp;&nbsp;${this.$t('errors.copyInviteLink')}`));
            }, 1000);
        },

        checkAccess() {
            let method = this.getChannel;

            if (this.card.isGroup()) method = this.getGroup;

            method(this.card.id)
                .then(v => {
                    if (v.hasBotIssue()) {
                        this.$notify('error', `😢 &nbsp;&nbsp;&nbsp;${this.$t('bot.disconnected')}`);
                    } else {
                        this.$store.commit('project/updateResource', v);

                        if (v.isChannel()) this.$store.commit('plans/editChannel', v);
                        if (v.isGroup()) this.$store.commit('plans/editGroup', v);

                        this.$store.commit('resources/edit', v);

                        this.getProject({ id: this.$cookies.get(STORAGE_LAST_OPENED_ITEM) });

                        this.$notify('success', `😊 &nbsp;&nbsp;&nbsp;${this.$t('bot.connected')}`);
                    }
                })
                .catch(v => this.$notify('error', v));
        },
        textRemovedItem(card) {
            let projectResources = [...this.project.channels, ...this.project.groups];
            this.name = `${card.title} ${this.$t('forms.willBeDeleted2')}`;
            let resourcesId = [...new Set(this.subscriptionResources.map(x => x.id))];
            this.removeItemShowBtnClose = true;

            if (resourcesId.includes(card.id)) {
                let titles = this.plans
                    .filter(p => {
                        if (p.canBeDeleted == false) return p;
                    })
                    .map(p => ` ${p.title}`);

                this.name = `${card.title} ${this.$t('resources.delete.cantWithSubscribers')
                    .replace(
                        '%tariff%',
                        titles.length > 1 ? this.$t('resources.tariff[1]') : this.$t('resources.tariff[0]')
                    )
                    .replace('%plan%', titles)}`;
                this.removeItemShowBtnClose = false;
            }

            /*_.each(this.lastResourceForPlans, p => {
				if ( !p.canBeDeleted ) {
					this.name = `${card.title} ${this.$t('resources.delete.cantWithSubscribers').replace('%plan%', p.title) }`;
					this.removeItemShowBtnClose = false;
				}
			})*/

            //if ( !this.removeItemShowBtnClose ) return this.name;

            if (projectResources.length == 1 && this.plans.length == 0 && !resourcesId.includes(card.id)) {
                this.name = `${this.name}`;
            }

            if (projectResources.length == 1 && this.plans.length != 0 && !resourcesId.includes(card.id)) {
                this.name = `${this.name}<br>${this.$t('resources.delete.lastPlan')}`;
            }

            if (
                this.lastResourceForPlans.length > 0 &&
                projectResources.length != 1 &&
                !resourcesId.includes(card.id)
            ) {
                this.name = `${this.name}<br>${this.$t('resources.delete.lastResource').replace('%plan%', this.lastResourceForPlans[0].title)}`;
            }
        },
        openModalRemove(card) {
            this.textRemovedItem(card);
            this.removeItem = _.cloneDeep(this.card);
            this.$bvModal.show(`resources-${this.card.id}`);
            this.onClosePopover(`resources-${this.card.id}`);
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.resources-card {
    min-height: 90px;
    padding: 15px 20px;

    @include respond-below(sm) {
        padding: 20px 17px;
    }

    &:last-child {
        @include respond-below(sm) {
            margin-bottom: 280px !important;
        }
    }

    &__item {
        position: relative;
        width: 100%;

        &::before {
            position: absolute;
            content: '';
            top: 50%;
            right: 0;
            width: 1px;
            height: 60px;
            background-color: var(--border-item-color);
            transform: translateY(-50%);
        }

        @include respond-below(sm) {
            width: 100%;

            &::before {
                content: none;
            }
        }

        &--dropdown-plans {
            max-width: 256px;
            min-width: 256px;

            @include respond-below(sm) {
                max-width: 100%;
                min-width: 100%;
            }
        }
    }

    &__avatars {
        width: 41px;
        min-width: 41px;
        height: 41px;
        min-height: 41px;
        font-size: 17px;
        line-height: 14px;
        text-transform: uppercase;
        color: var(--foreground-color);
        border-radius: 50%;

        img {
            display: block;
            border-radius: 50%;
            width: 100%;
            height: 100%;
        }

        &.brand-gradient {
            background: var(--brand-gradient-primary, linear-gradient(227deg, #0071f4 0%, #66b4ff 100%));
        }
    }

    &__resource {
        position: relative;
        padding-left: 31px;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url('/assets/img/current/telegram.svg');
            transform: translateY(-50%);
        }
    }

    &__no-plans {
        height: 50px;
        padding: 14px 15px;
        background-color: var(--grayscale-bg-dark-back);
        border-radius: var(--border-radius-rounded);
        color: var(--red-text-color);
    }

    &__item-alert {
        &:first-child {
            flex-grow: 1;
            min-width: 190px;

            @include respond-below(lg) {
                min-width: 100%;
            }

            @include respond-below(sm) {
                min-width: calc(60% - 0.25rem);
            }
        }

        &:nth-child(2) {
            min-width: 120px;
            flex-grow: 1;
            transition: var(--animation-time-short);
            cursor: pointer;

            @include respond-below(lg) {
                min-width: 100%;
            }

            @include respond-below(sm) {
                min-width: 40%;
            }

            svg path {
                transition: var(--animation-time-short);
            }

            &:hover {
                color: var(--primary-dark-text-color);

                svg path {
                    fill: var(--primary-dark-text-color);
                }
            }

            &:active {
                color: var(--primary-dark-text-color);
                background-color: var(--primary-dark-bg-color);

                svg path {
                    fill: var(--primary-dark-text-color);
                }
            }
        }
    }

    &__dots {
        min-width: 18px;
        min-height: 18px;
    }

    &__tooltip {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 24px;
        min-height: 24px;
        max-width: 24px;
        max-height: 24px;
        color: var(--brand-error);
        background-color: var(--foreground-color);
        border-radius: 50%;

        svg {
            width: 14px;
            height: 13px;
        }
    }

    &__modal-bot {
        padding: 16px 20px 28px;
    }

    &__modal-invite {
        max-width: 403px;
        padding: 15px 25px 20px;
        margin: 0 auto;

        @include respond-below(sm) {
            max-width: 100%;
            padding: 16px 20px 75px;
        }
    }

    &__invite-copy {
        padding: 16px 15px;
        color: var(--primary-text-color);
        background-color: var(--primary-bg-color);
        border-radius: var(--border-radius-rounded);
        transition: var(--animation-time-short);
        cursor: pointer;

        &:hover {
            color: var(--primary-dark-text-color);
        }

        &:active {
            color: var(--primary-dark-text-color);
            background-color: var(--primary-dark-bg-color);
        }

        .fontello-icon-copy {
            font-size: 20px;
        }
    }
}
</style>
